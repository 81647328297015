import React, { useEffect, useState, useCallback } from "react";
import { GoogleMap, useJsApiLoader, Marker, Polyline, InfoWindow } from "@react-google-maps/api";
import customMarkerImage from "../../assets/images/startPointIcon.png";
import truckSpeed from "../../assets/images/truckSpeed.png";
import moment from 'moment';

const containerStyle = {
  height: "400px",
};

function MapView({ apiKey, coordinates = [], lines = true, linesCordinates = [] }) {
  const [center, setCenter] = useState(null);
  const [zoom, setZoom] = useState(16); 
  const [markersState, setMarkersState] = useState([]);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: 'AIzaSyACj8jGFboR32OVLAE_N7Uu4uFzQtvmwgM',
  });

  const [map, setMap] = useState(null);

  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {
    if (coordinates?.length > 0) {
      const avgLat =
        coordinates.reduce((acc, marker) => acc + marker.lat, 0) /
        coordinates?.length;
      const avgLng =
        coordinates.reduce((acc, marker) => acc + marker.lng, 0) /
        coordinates?.length;
      setCenter({ lat: avgLat, lng: avgLng });
      setMarkersState(coordinates);
    } else {
      setCenter({ lat: 37.7749, lng: -122.4194 });
    }
  }, [coordinates]);

  useEffect(() => {
    if (markersState?.length > 0) {
      setCenter({ lat: markersState[0].lat, lng: markersState[0].lng });
      setZoom(14);
    }
  }, [markersState]);

  if (loadError) {
    console.error("Error loading Google Maps API:", loadError);
    return <div>Error loading map</div>;
  }

  const dashedLineSymbol = {
    path: "M 0,-1 0,1",
    strokeOpacity: 1,
    scale: 6,
  };

  return isLoaded && center ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {selectedMarker && (
        <InfoWindow
          position={{ lat: selectedMarker.lat, lng: selectedMarker.lng }}
          onCloseClick={() => setSelectedMarker(null)}
        >
          <div>
            <h3>{selectedMarker.messageTitle || `Marker ${selectedMarker.lat}, ${selectedMarker.lng}`}</h3>
            <p>{selectedMarker.messageDescription || "No additional information."}</p>
            {selectedMarker.timeStopped && <p>{selectedMarker.timeStopped}</p>}
            {selectedMarker.startTime && <p>{selectedMarker.startTime}</p>}
            {selectedMarker.endTime && <p>{selectedMarker.endTime}</p>}
          </div>
        </InfoWindow>
      )}
      {lines && linesCordinates?.length > 0 && (
        <>
          {linesCordinates.map((coordinate, index) =>
            coordinate.speed === 0 && index > 0 ? (
              (() => {
                const previousCoordinate = linesCordinates[index - 1];
                const currentTimestamp = moment(coordinate.timestamp, "HH:mm:ss");
                const previousTimestamp = moment(previousCoordinate.timestamp, "HH:mm:ss");

                const timeDiff = currentTimestamp.diff(previousTimestamp, 'seconds');

                // Only show marker if the stop time is 10 minutes (600 seconds) or greater
                if (timeDiff >= 600) {
                  return (
                    <Marker
                      key={`marker-${index}`}
                      position={{ lat: coordinate.lat, lng: coordinate.lng }}
                      icon={{
                        url: truckSpeed,
                        scaledSize: new window.google.maps.Size(40, 40),
                        anchor: new window.google.maps.Point(20, 20),
                      }}
                      onClick={() => {
                        const hours = Math.floor(timeDiff / 3600);
                        const minutes = Math.floor((timeDiff % 3600) / 60);
                        const seconds = timeDiff % 60;

                        const formattedTime = [];
                        if (hours > 0) formattedTime.push(`${hours} hour${hours !== 1 ? 's' : ''}`);
                        if (minutes > 0) formattedTime.push(`${minutes} minute${minutes !== 1 ? 's' : ''}`);
                        if (seconds > 0) formattedTime.push(`${seconds} second${seconds !== 1 ? 's' : ''}`);

                        // Format start and end times
                        const startTime = previousTimestamp.format("HH:mm:ss");
                        const endTime = currentTimestamp.format("HH:mm:ss");

                        // Update the selectedMarker state with start and end time along with duration
                        setSelectedMarker({
                          lat: coordinate.lat,
                          lng: coordinate.lng,
                          messageTitle: "Time Stopped",
                          messageDescription: "The truck has stopped.",
                          timeStopped: `Duration: ${formattedTime.join(', ')}`, // Include the formatted time
                          startTime: `Start Time: ${startTime}`, // Include start time
                          endTime: `End Time: ${endTime}`, // Include end time
                        });
                      }}
                    />
                  );
                }
                return null;
              })()
            ) : null
          )}

          <Marker
            position={{ lat: linesCordinates[0].lat, lng: linesCordinates[0].lng }}
            icon={{
              url: customMarkerImage,
              scaledSize: new window.google.maps.Size(60, 60),
              anchor: new window.google.maps.Point(30, 30),
              rotation: linesCordinates[0].heading,
            }}
          />
          <Polyline
            path={linesCordinates}
            options={{
              strokeColor: "#FF8126",
              strokeOpacity: 0,
              icons: [
                {
                  icon: dashedLineSymbol,
                  offset: "0",
                  repeat: "20px",
                },
              ],
            }}
          />
        </>
      )}
      {coordinates?.length !== 0 &&
        markersState?.map((marker, index) => (
          <Marker
            key={index}
            position={{ lat: marker.lat, lng: marker.lng }}
            icon={{
              url: marker.icon?.default || customMarkerImage,
              scaledSize: new window.google.maps.Size(40, 40),
              anchor: new window.google.maps.Point(20, 20),
            }}
            onClick={() => setSelectedMarker(marker)}
          />
        ))}
    </GoogleMap>
  ) : (
    <div>Loading map...</div>
  );
}

export default MapView;
