import { DashboardIcon, language } from "../../assets/wasfaty/SVG";
// Static Side Bar
const navigationLinks = [
  // {
  //   id: "dashboards",
  //   slug: "dashboards",
  //   title: "Dashboards",
  //   icon: () => DashboardIcon,
  //   navLink: "/",
  // },
];

export default navigationLinks;
