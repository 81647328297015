// ** React Imports
import { Outlet } from "react-router-dom";
import {
  inventory,
  report_center,
  administration,
  sales,
  synchronization,
  merchandising, 
  DashboardIcon
} from "../assets/wasfaty/SVG";
import Layout from "@layouts/VerticalLayout";
import navigation from "@src/navigation/vertical";
import { useEffect, useState } from "react";

const VerticalLayout = (props) => {
  const [sideBarList, setSideBarList] = useState([]);
  const iconMapping = {
    inventory: inventory,
    report_center: report_center,
    sales: sales,
    inventory: inventory,
    administration: administration,
    synchronization: synchronization,
    merchandising: merchandising,
    dashboards: DashboardIcon
  };

  useEffect(() => {
    getSideBarData();
  }, []);

  const getSideBarData = async () => {
    let features_groups = JSON.parse(localStorage?.getItem("features_groups"));
    if (features_groups !== null) {
      let dynamicNav = [
        ...features_groups?.feature_groups?.map((item) => ({
          id: item?.slugs,
          slug: item?.slugs,
          title: item?.groupName,
          navLink: item?.groupId === 26 ? "/" : "/" + item?.slugs + "/" + item?.groupId,
          icon: () => iconMapping[item?.slugs] || (() => [item?.slugs]),
        })),
      ];
      console.log("=-=-dynamicNav", dynamicNav)
      setSideBarList(dynamicNav);
    }

    // const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");

    // const raw = JSON.stringify({
    //   user_id: 2381,
    //   password: "123456",
    // });

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };

    // fetch(
    //   "https://theia.sbctanzania.co.tz:8083/theia/authentication/C01PortalAuthenticate",
    //   requestOptions
    // )
    //   .then((response) => response.text())
    //   .then((result) => {
    //     let response = JSON.parse(result)?.DATA?.feature_groups;
    //     console.log("=-=-responseHuzam", response);

    //   })
    //   .catch((error) => console.error(error));
  };

  return (
    <Layout menuData={[...navigation, ...sideBarList]} {...props}>
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
