import Timeline from '@components/timeline';
import { useEffect, useState } from 'react';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

const TimelineComp = ({ title, processSteps = [], activeTab }) => {
    const [timelineData, setTimelineData] = useState([]);

    useEffect(() => {
        if (processSteps && Array.isArray(processSteps)) {
            const mappedSteps = processSteps.map((step) => {
                const isGray = !step.stepActionLabel;
                const color = isGray
                    ? 'secondary'
                    : activeTab === '3' && step.stepActionLabel === 'Decline'
                        ? 'danger'
                        : activeTab === '2'
                            ? 'success'
                            : step.isCurrentStep
                                ? 'warning'
                                : 'success';

                const title = isGray
                    ? step.processActionLabel || 'No process action label'
                    : step.isCurrentStep
                        ? step.requestStatusLabel || 'No request status label'
                        : step.stepActionLabelPast || 'No action label';

                const content = (activeTab === '2' || activeTab === '3')
                    ?
                    <>
                        <img src={step.stepByUserImage || ""} height="23" style={{ borderRadius: 100 / 2 }} />   By {step.stepByName || step.stepUserRole}
                    </>
                    : step.isCurrentStep
                        ? `To ${step.stepByName || 'Unknown'}`
                        : isGray
                            ? (
                                <>
                                    <img src={step.stepByUserImage || ""} height="23" style={{ borderRadius: 100 / 2 }} />   By {step.stepUserRole || 'Unknown Role'}
                                </>
                            )
                            : (
                                <>
                                    <img src={step.stepByUserImage || ""} height="23" style={{ borderRadius: 100 / 2 }} />  By  {step.stepByName || 'Unknown'}
                                </>
                            );

                return {
                    title,
                    content,
                    meta: step.stepCompletedOn ? new Date(step.stepCompletedOn).toDateString() : '',
                    color,
                    remarks: step.stepRemarks || null,

                };
            });
            setTimelineData(mappedSteps);
        } else {
            console.error("processSteps is not defined or not an array");
        }
    }, [processSteps, activeTab]);

    return (
        <Card>
            <CardHeader>
                <CardTitle tag='h4'>
                    {title}
                    {(activeTab !== '3' || activeTab === "2") && processSteps.some(step => step.isCurrentStep) && (
                        <span style={{ fontWeight: 'normal', marginLeft: '10px', backgroundColor: '#0E72AD', padding: 5, color: 'white', fontSize: 10, borderRadius: 5, fontWeight: 'bold' }}>
                            {`Level  ${processSteps.find(step => step.isCurrentStep)?.stepId || 'No action ID'}`}
                        </span>
                    )}
                </CardTitle>
            </CardHeader>
            <CardBody className='pt-1'>
                <Timeline data={timelineData} className='ms-50' />
            </CardBody>
        </Card>
    );
}

export default TimelineComp;
