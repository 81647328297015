import React, { useState, useEffect } from "react";
import { useSkin } from "@hooks/useSkin";
import { Link } from "react-router-dom";
import InputPasswordToggle from "@components/input-password-toggle";
import Carousel from "@components/carousel";
import {
  Row,
  Col,
  CardTitle,
  CardText,
  Form,
  Label,
  Input,
  Button,
} from "reactstrap";
import "@styles/react/pages/page-authentication.scss";
import { SC } from "../../services/Api/serverCall";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { setUserSlice } from "../../redux/userSlice";
import { generateToken, messaging } from "../../services/Firebase/notifications";
import ip from 'ip';


const Login = () => {
  const { skin } = useSkin();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userid, setUserid] = useState("");
  const [password, setPassword] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [userIdError, setUserIdError] = useState(""); // State to track UserId error message

  const items = [
    {
      src: require(`@src/assets/images/pages/banner-1.jpg`).default,
      altText: "Slide 1",
      caption: "Slide 1",
      key: 1,
    },
    {
      src: require(`@src/assets/images/pages/banner_6.jpg`).default,
      altText: "Slide 2",
      caption: "Slide 2",
      key: 2,
    },
    {
      src: require(`@src/assets/images/pages/banner-1.png`).default,
      altText: "Slide 3",
      caption: "Slide 3",
      key: 3,
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const validateUserId = (value) => {
    const userIdPattern = /^[A-Z0-9]+$/;
    if (value && !userIdPattern.test(value)) {
      setUserIdError("User ID must contain only uppercase letters and numbers!");
    } else {
      setUserIdError("");
    }
  };



  const handleUserIdChange = (e) => {
    // Remove '@' character from input value
    const newValue = e.target.value.replace(/@/g, "");
    setUserid(newValue);
    validateUserId(newValue);
  };

  async function getPublicIp() {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      console.log("Public IPv4 Address:", data.ip);
      return data.ip;
    } catch (error) {
      console.error("Error fetching public IP address:", error);
      return null;
    }
  }

  function generateNumericBrowserId() {
    const userAgent = navigator.userAgent;
    let hash = 0;

    for (let i = 0; i < userAgent.length; i++) {
      const char = userAgent.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash |= 0;
    }

    return Math.abs(hash);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (userIdError) {
      return toast.error(userIdError);
    }
    if (userid === "" || password === "") {
      return toast.error("All Fields are Required!");
    }

    const fcmToken = await generateToken();
    const localIp = await getPublicIp();
    const browserId = generateNumericBrowserId();

    const payload = {
      user_id: userid,
      password: password,
      fcm_token: fcmToken,
      localIp: localIp,
      lat: "",
      lng: "",
      browser_id: browserId
    };


    SC.postCallWithoutAuth({
      url: "authentication/C01PortalAuthenticate",
      data: payload,
    })
      .then((res) => {
        let response = JSON.parse(JSON.stringify(res));
        console.log("AUTH RESPONSE", response);
        if (response?.status === 200) {
          if (response?.data?.CODE === 401) {
            toast.error(response?.data?.USER_MESSAGE);
          } else {
            localStorage.setItem(
              "userData",
              JSON.stringify(response?.data?.DATA)
            );
            localStorage.setItem(
              "accessToken",
              JSON.stringify(response?.data?.DATA?.jwt_token)
            );
            localStorage.setItem(
              "features_groups",
              JSON.stringify(response?.data?.DATA)
            );
            dispatch(setUserSlice(response?.data?.DATA));
            toast.success(response?.data?.USER_MESSAGE);
          }
        } else if (response?.status === 401) {
          toast.error("Invalid Credentials!");
        } else if (response?.status === 500) {
          toast.error("Internal Server Error!");
        } else if (response?.status === 404) {
          toast.error("User Not Found!");
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  const getGeolocationAndSubmit = async (fcmToken, localIp) => {
    navigator.geolocation.getCurrentPosition(
      async (position) => {
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;


      },
      (error) => {
        toast.error("Unable to retrieve location.");
        console.error("Error getting location:", error);
      }
    );
  };


  return (
    <Row style={{ height: "100vh" }}>
      <Col
        md="12"
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          padding: "0 15px",
          background: `url(${items[1].src}) no-repeat center center`,
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "400px",
            padding: "30px",
            borderRadius: "10px",
            boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <CardTitle tag="h2" className="fw-bold mb-1">
            Welcome! 👋
          </CardTitle>
          <CardText className="mb-2">Please sign-in to your account.</CardText>
          <Form className="auth-login-form mt-2" onSubmit={handleSubmit}>
            <div className="mb-1">
              <Label className="form-label" for="login-email">
                User Id
              </Label>
              <Input
                type="text"
                id="login-email"
                placeholder="DARXX00"
                autoFocus
                value={userid}
                onChange={handleUserIdChange}
                invalid={!!userIdError} // Adds red border if there's an error
              />
              {userIdError && (
                <div style={{ color: "red", marginTop: "5px" }}>
                  {userIdError}
                </div>
              )}
            </div>
            <div className="mb-1">
              <div className="d-flex justify-content-between">
                <Label className="form-label" for="login-password">
                  Password
                </Label>
                <Link to="/forgot-password">
                  <small>Forgot Password?</small>
                </Link>
              </div>
              <InputPasswordToggle
                className="input-group-merge"
                id="login-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <Button color="primary" block>
              Sign in
            </Button>
            <div className="d-flex justify-content-center mt-3">
              <h5>
                Powered By{" "}
                <a style={{ color: "#1E90FF" }} href="http://kale-labs.com/">
                  Kale Labs
                </a>
              </h5>
            </div>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
