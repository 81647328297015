import { Fragment, useState, useEffect } from 'react';
import {
    Row, Col, CardHeader, Card, CardBody,
    Spinner, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input
} from 'reactstrap';
import Select from "react-select";
import BreadCrumbs from '@components/breadcrumbs';
import Timeline from '../../components/Timeline';
import { useNavigate, useLocation } from 'react-router-dom';
import '@styles/react/apps/app-users.scss';
import { SC } from '../../../services/Api/serverCall';
import MapView from "../../components/MapView";
import DaysSelector from '../../components/DaysSelector'
import toast from 'react-hot-toast';
import { selectThemeColors } from "@utils";
import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const daysOfWeek = [
    { label: "Sun", id: 1 },
    { label: "Mon", id: 2 },
    { label: "Tue", id: 3 },
    { label: "Wed", id: 4 },
    { label: "Thr", id: 5 },
    { label: "Fri", id: 6 },
    { label: "Sat", id: 7 }
];

const WorkFlowDetail = ({ }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { myRequestsData, activeTab } = location.state || {};
    const [loader, setLoader] = useState(false)
    const [coordinatesLoader, setCoordinatesLoader] = useState(false)
    const [loaderApprove, setLoaderApprove] = useState(false)
    const [loaderDecline, setLoaderDecline] = useState(false)
    const [remarksModalOpen, setRemarksModalOpen] = useState(false)
    const [onSelectChannel, setOnSelectChannel] = useState(null)
    const [onSelectPrice, setOnSelectPrice] = useState(null)
    const [proceedClick, setProceedClick] = useState(false)
    const [approveDecline, setApproveDecline] = useState(false)
    const [requiredCheck, setRequiredCheck] = useState(false)
    const [coordinates, setCoordinates] = useState([])
    const [rquestResponse, setRquestResponse] = useState([])
    const [actionIdState, setActionIdState] = useState(null)
    const [userRemark, setUserRemark] = useState('')
    const [slectedDays, setSlectedDays] = useState([])
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
    const [replannedInvoices, setReplannedInvoices] = useState([]);
    const [cancelledInvoices, setCancelledInvoices] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [selectedReliver, setSelectedReliver] = useState(null);
    const [selectedPJPDrop, setSelectedPJPDrop] = useState(null);
    const [isReliver, setIsReliver] = useState(false);
    const [updateOutletImage, setUpdateOutletImage] = useState(true);
    const [formInputs, setFormInputs] = useState([]);

    const checkTimeForButton = () => {
        const now = new Date();
        const currentHour = new Date(now.toLocaleString('en-US', { timeZone: 'Africa/Dar_es_Salaam' })).getHours();

        if (currentHour >= 19 || currentHour < 0) {
            setIsButtonDisabled(true);
        } else {
            setIsButtonDisabled(false);
        }
    };

    useEffect(() => {
        checkTimeForButton();

        const intervalId = setInterval(checkTimeForButton, 60000);
        return () => clearInterval(intervalId);
    }, []);

    const isImage = (value) => {
        return typeof value === 'string' && imageExtensions.some(ext => value.toLowerCase().endsWith(ext));
    };

    useEffect(() => {
        getRequestDetail()
    }, [])

    const getRequestDetail = () => {
        setLoader(true)
        let payload = {
            "processId": myRequestsData?.processId,
            "requestId": myRequestsData?.requestId
        }

        SC.postCall({
            url: 'portal/C01PortalGetWorkflowRequestDetails',
            data: payload
        })
            .then((response) => {
                console.log("C01PortalGetWorkflowRequestDetails", response)
                setRquestResponse(response?.data?.DATA)
                selectedDaysCall(response?.data?.DATA?.requestDetailsData?.Outlet_Request_Routes)
                onSetCoordinates(response?.data?.DATA?.requestDetailsData)
                if (response?.data?.DATA?.requestDetailsData?.Outlet_Channels?.length !== 0) {
                    setRequiredCheck(true)
                }
                setLoader(false);
            })
            .catch((error) => {
                console.log('ERROR', error);
                setLoader(false);
            });
    }

    const onSetCoordinates = (item) => {
        setCoordinatesLoader(true);
        let array = []
        const outletLat = item?.Lat ?? null;
        const outletLng = item?.Lng ?? null;

        if (outletLat !== null && outletLng !== null) {
            const updatedCoordinates = [
                ...array,
                {
                    lat: outletLat,
                    lng: outletLng,
                    icon: require("../../../assets/images/outletMapMarker.png"),
                    type: "outlet",
                },
            ];
            setCoordinates(updatedCoordinates);
        }

        setCoordinatesLoader(false);
    };

    const selectedDaysCall = (days) => {
        setSlectedDays(days)
    };

    const onUpdateRquestAPI = () => {
        setProceedClick(true)
        if (myRequestsData?.processName === "Outlet Registration Request" && actionIdState !== 2) {
            if (slectedDays?.length === 0) {
                toast?.error("Select at least one day");
                setProceedClick(false);
                return;
            }
            if (requiredCheck) {
                if (onSelectChannel === null) {
                    toast?.error("Please Select Channel");
                    setProceedClick(false);
                    return;
                }

                if (onSelectPrice === null) {
                    toast?.error("Please Select Price Group");
                    setProceedClick(false);
                    return;
                }
            }
            if (actionIdState === 1) {
                setLoaderApprove(true)
            }
            if (actionIdState === 2) {
                setLoaderDecline(true)
            }
        } else if (myRequestsData?.processName === "Truck BreakDown Request" && actionIdState !== 2) {
            console.log("=-234567890-", rquestResponse.requestDetailsData)
            console.log("=-234replannedInvoices567890-", replannedInvoices)
            if ((replannedInvoices?.length + cancelledInvoices?.length) !== rquestResponse.requestDetailsData?.Invoices?.length) {
                toast?.error("Please select all Invoices!");
                setProceedClick(false);
                return;
            }
        } else if (myRequestsData?.processName === "Dispatch Closure Request" && actionIdState !== 2) {
            if ((replannedInvoices?.length + cancelledInvoices?.length) !== rquestResponse.requestDetailsData?.Invoices?.length) {
                toast?.error("Please select all Invoices!");
                setProceedClick(false);
                return;
            }
        }

        const userData = JSON.parse(localStorage.getItem('userData'))
        let payload = {
            "requestId": myRequestsData?.requestId,
            "actionId": actionIdState,
            "userId": userData?.id,
            "remarks": userRemark,
            "days": slectedDays,
            "cancelledInvoices": replannedInvoices,
            "replannedInvoices": cancelledInvoices,
            "channelId": onSelectChannel?.value,
            "priceListId": onSelectPrice?.value,
            "beat_plan_id": selectedPJPDrop,
            "updateImage": updateOutletImage || false
        }

        if (myRequestsData?.processName === "Leave Request") {
            payload.isReliever = isReliver
            payload.relieverId = selectedReliver || null

        }

        console.log("payload worfflow", payload)

        SC.postCall({
            url: 'portal/C01PortalWorkflowRequestPerformAction',
            data: payload
        })
            .then((response) => {
                if (response?.data?.CODE === 1) {
                    toast?.success(response?.data?.USER_MESSAGE)
                    setLoaderApprove(false)
                    setLoaderDecline(false)
                    setRemarksModalOpen(false)
                    setApproveDecline(true)
                    getRequestDetail()
                }
                setLoader(false);
                setProceedClick(false)

            })
            .catch((error) => {
                console.log('ERROR', error);
                setLoader(false);
                setLoaderApprove(false)
                setLoaderDecline(false)
                setRemarksModalOpen(false)
                setProceedClick(false)
            });
    }

    const onSelectRequests = (type, invoiceId) => {
        if (type === 'replanned') {
            setReplannedInvoices((prev) => {
                if (!prev.includes(invoiceId)) {
                    return [...prev, invoiceId];
                }
                return prev;
            });
        } else if (type === 'cancelled') {
            setCancelledInvoices((prev) => {
                if (!prev.includes(invoiceId)) {
                    return [...prev, invoiceId];
                }
                return prev;
            });
        }
    };

    const getDropDownList = (_id) => {

        console.log("=-=-Huzam Mughal")

        let payload = {
            typeId: _id,
        };

        SC.postCall({
            url: "portal/C01PortalGetDropdown",
            data: payload,
        })
            .then((res) => {
                let response = JSON.parse(JSON.stringify(res));
                console.log("RES C01PortalGetDropdown", response);
                let newFilterItems = response?.data?.DATA?.map((i) => ({
                    value: i?.id,
                    label: i?.label,
                }));
                if (_id === "18") {
                    setFormInputs((prevState) => ({
                        ...prevState,
                        ["selectPjpDrop"]: newFilterItems
                    }));
                }
            })
            .catch((error) => {
                console.log("ERROR", error);
            });
    };

    return (
        <>
            <div className='app-user-view'>
                <BreadCrumbs
                    title="Theia"
                    data={[{ title: "Request List" }, { title: "Summary" }]}
                    onPress={() => navigate(-1)}
                    onPressReport={() => { }}
                />
                <Row>
                    <Col md='3'>
                        <Timeline
                            title={"Approval Chain"}
                            processSteps={rquestResponse?.requestChain}
                            activeTab={activeTab}
                        />
                        {(activeTab === "1" && !approveDecline) &&
                            <>
                                <Row style={{ marginLeft: '5%', marginRight: '5%' }}>
                                    <Button
                                        color="primary"
                                        type="submit"
                                        style={{ marginBottom: '5%' }}
                                        onClick={(e) => {
                                            if (rquestResponse?.requestDetailsData?.PJP_Name === null
                                                && myRequestsData?.processName === "Outlet Registration Request"
                                                && activeTab === "1"
                                                && selectedPJPDrop === null
                                            ) {
                                                toast.error("Please Select the Pjp")
                                            } else if (myRequestsData?.processName === "Leave Request" && isReliver && selectedReliver === null) {
                                                toast.error("Please Select the Reliver Option")
                                            } else {
                                                setActionIdState(1)
                                                setRemarksModalOpen(true)
                                            }
                                        }}
                                    >
                                        {rquestResponse?.requestChain?.find((step) => step.isCurrentStep)?.stepActionButtonLabel}
                                    </Button>
                                    <Button
                                        color="danger"
                                        type="submit"
                                        style={{ marginBottom: '5%' }}
                                        onClick={(e) => {
                                            setRemarksModalOpen(true)
                                            setActionIdState(2)
                                        }}
                                    >
                                        Decline
                                    </Button>
                                </Row>
                            </>
                        }
                    </Col>
                    <Col md='9'>
                        <Card>
                            <CardHeader>
                                <h4>{myRequestsData?.processName}</h4>
                            </CardHeader>
                            <CardBody>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'wrap',
                                    justifyContent: 'space-between'
                                }}>
                                    {loader ? (
                                        <Spinner />
                                    ) : (
                                        !!rquestResponse?.requestDetailsData && (() => {
                                            const data = rquestResponse.requestDetailsData;
                                            const textData = [];
                                            const imageData = [];
                                            let daysData = [];
                                            let dropdownOption = []
                                            const keysToSkip = ["Selected_Price_List_Id", "Selected_Channel_Id"];
                                            console.log("=-=data", data)
                                            Object.keys(data).forEach((key) => {
                                                if (keysToSkip.includes(key)) {
                                                    return;
                                                }
                                                const value = data[key];
                                                if (Array.isArray(value)) {
                                                    daysData = data?.Outlet_Request_Routes;
                                                } else if (isImage(value)) {
                                                    imageData.push({ key, value });
                                                } else {
                                                    textData.push({ key, value });
                                                }
                                                if (data?.RelieverList) {
                                                    dropdownOption = data?.RelieverList?.map((res) => (
                                                        { value: res?.relieverId, label: res?.relieverName }
                                                    ))
                                                }
                                            });
                                            return (
                                                <>

                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                                                        <div>
                                                            {myRequestsData?.processName === "Outlet Registration Request" &&
                                                                <DaysSelector
                                                                    daysOfWeek={daysOfWeek}
                                                                    initialSelectedDays={daysData}
                                                                    selectedDaysCall={selectedDaysCall}
                                                                    size={"sm"}
                                                                />
                                                            }
                                                        </div>
                                                        <div>
                                                            {textData.map(({ key, value }, index) => (
                                                                <>
                                                                    {key === "PJP_Name" && !value ?
                                                                        <>
                                                                            {activeTab === "1" && myRequestsData?.processName === "Outlet Registration Request"
                                                                                &&
                                                                                <Select
                                                                                    theme={selectThemeColors}
                                                                                    className='react-select'
                                                                                    classNamePrefix='select'
                                                                                    placeholder="Select Route"
                                                                                    options={formInputs?.selectPjpDrop}
                                                                                    isClearable={false}
                                                                                    onFocus={() => getDropDownList("18")}
                                                                                    onChange={({ value }) => setSelectedPJPDrop(value)}
                                                                                />
                                                                            }
                                                                        </>
                                                                        :
                                                                        null
                                                                    }
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    {(!!data?.Outlet_Channels?.length || data?.Outlet_Channels?.length !== 0) &&
                                                        ((activeTab === "1" || activeTab === "2") && myRequestsData?.processName === "Outlet Registration Request") &&
                                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
                                                            <div>
                                                                <Select
                                                                    styles={{
                                                                        container: (provided) => ({
                                                                            ...provided,
                                                                            width: 200,
                                                                            marginRight: 10,
                                                                        }),
                                                                    }}
                                                                    isClearable={false}
                                                                    placeholder="Price Group"
                                                                    theme={selectThemeColors}
                                                                    closeMenuOnSelect={true}
                                                                    components={animatedComponents}
                                                                    isDisabled={activeTab === "2"}
                                                                    value={
                                                                        activeTab === "2"
                                                                            ? data?.Price_Groups?.find((item) => item?.Price_List_Id === data?.Selected_Price_List_Id)
                                                                                ? {
                                                                                    value: data?.Price_Groups?.find((item) => item?.Price_List_Id === data?.Selected_Price_List_Id)?.Price_List_Id,
                                                                                    label: data?.Price_Groups?.find((item) => item?.Price_List_Id === data?.Selected_Price_List_Id)?.Price_List_Label,
                                                                                }
                                                                                : null
                                                                            : onSelectPrice
                                                                    }
                                                                    isMulti={false}
                                                                    options={
                                                                        activeTab !== "2"
                                                                            ? data?.Price_Groups?.map((item) => ({
                                                                                value: item.Price_List_Id,
                                                                                label: item.Price_List_Label,
                                                                            })) || []
                                                                            : []
                                                                    }
                                                                    className="react-select"
                                                                    classNamePrefix="select"
                                                                    onChange={(res) => setOnSelectPrice(res)}
                                                                />
                                                            </div>
                                                            <div>
                                                                <Select
                                                                    styles={{
                                                                        container: (provided) => ({
                                                                            ...provided,
                                                                            width: 200,
                                                                            marginRight: 10,
                                                                        }),
                                                                    }}
                                                                    isClearable={false}
                                                                    placeholder="Channel"
                                                                    theme={selectThemeColors}
                                                                    closeMenuOnSelect={true}
                                                                    components={animatedComponents}
                                                                    isDisabled={activeTab === "2"}
                                                                    value={
                                                                        activeTab === "2"
                                                                            ? data?.Outlet_Channels?.find((item) => item?.Channel_id === data?.Selected_Channel_Id)
                                                                                ? {
                                                                                    value: data?.Outlet_Channels?.find((item) => item?.Channel_id === data?.Selected_Channel_Id)?.Channel_id,
                                                                                    label: data?.Outlet_Channels?.find((item) => item?.Channel_id === data?.Selected_Channel_Id)?.Channel_label,
                                                                                }
                                                                                : null
                                                                            : onSelectChannel
                                                                    }
                                                                    isMulti={false}
                                                                    options={
                                                                        activeTab !== "2"
                                                                            ? data?.Outlet_Channels?.map((item) => ({
                                                                                value: item.Channel_id,
                                                                                label: item.Channel_label,
                                                                            })) || []
                                                                            : []
                                                                    }
                                                                    className="react-select"
                                                                    classNamePrefix="select"
                                                                    onChange={(res) => setOnSelectChannel(res)}
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                        {textData.map(({ key, value }, index) => (
                                                            <>
                                                                {key === "PJP_Name" && !value ?
                                                                    null
                                                                    :
                                                                    <div
                                                                        key={index}
                                                                        style={{
                                                                            backgroundColor: '#F8F8F8',
                                                                            padding: 8,
                                                                            borderRadius: 5,
                                                                            width: 'calc(33% - 10px)',
                                                                            marginBottom: 10
                                                                        }}
                                                                    >
                                                                        <span style={{ fontSize: 12, fontWeight: 'bold' }}>{key.replace(/_/g, ' ')}</span>
                                                                        <div
                                                                            style={{
                                                                                fontSize: 10,
                                                                                marginTop: 5,
                                                                                color: '#0E72AD',
                                                                                fontWeight: 'bold'
                                                                            }}
                                                                        >
                                                                            {value || 'N/A'}
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        ))}

                                                        {myRequestsData?.processName === "Leave Request" &&
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                                <div>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='form-check form-switch'>
                                                                        <Input
                                                                            type="switch"
                                                                            id="switch-success"
                                                                            name="success"
                                                                            checked={isReliver}
                                                                            onChange={() => setIsReliver(!isReliver)}
                                                                            style={{
                                                                                transform: "scale(0.75)",
                                                                                WebkitTransform: "scale(0.75)",
                                                                                backgroundColor: isReliver ? "green" : "gray",
                                                                                borderColor: isReliver ? "green" : "gray",
                                                                            }}
                                                                        />
                                                                        <div style={{ fontWeight: 'bold', width: '100%' }} >
                                                                            {isReliver ? "Reliever" : "Call Center "}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {isReliver &&
                                                                    <Select
                                                                        theme={selectThemeColors}
                                                                        className='react-select'
                                                                        classNamePrefix='select'
                                                                        placeholder="Select Reliver"
                                                                        options={dropdownOption}
                                                                        isClearable={false}
                                                                        onChange={({ value }) => setSelectedReliver(value)}
                                                                    />
                                                                }
                                                            </div>
                                                        }
                                                        {myRequestsData?.processName === "Update Outlet Coordinates" &&
                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                                                                <div>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} className='form-check form-switch'>
                                                                        <Input
                                                                            type="switch"
                                                                            id="switch-success"
                                                                            name="success"
                                                                            checked={updateOutletImage}
                                                                            onChange={() => setUpdateOutletImage(!updateOutletImage)}
                                                                            style={{
                                                                                transform: "scale(0.75)",
                                                                                WebkitTransform: "scale(0.75)",
                                                                                backgroundColor: isReliver ? "green" : "gray",
                                                                                borderColor: isReliver ? "green" : "gray",
                                                                            }}
                                                                        />
                                                                        <div style={{ fontWeight: 'bold', width: '100%' }} >
                                                                            Update Outlet Image
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {data?.Outlet_Request_Contacts?.length > 0 &&
                                                            <>
                                                                <h3 style={{ marginTop: 20 }}>Contacts Detail</h3>
                                                                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px', }}>
                                                                    {data.Outlet_Request_Contacts.map((contact, index) => (
                                                                        <div
                                                                            key={index}
                                                                            style={{
                                                                                backgroundColor: '#F0F0F0',
                                                                                padding: 10,
                                                                                borderRadius: 5,
                                                                                width: '300px',
                                                                                marginBottom: 10
                                                                            }}
                                                                        >
                                                                            {contact.Owner_Name && (
                                                                                <div>
                                                                                    <span style={{ fontSize: 12, fontWeight: 'bold' }}>Owner Name:</span>
                                                                                    <div style={{ fontSize: 10, marginTop: 5 }}>{contact.Owner_Name}</div>
                                                                                </div>
                                                                            )}
                                                                            {contact.Owner_Contact && (
                                                                                <div>
                                                                                    <span style={{ fontSize: 12, fontWeight: 'bold' }}>Owner Contact:</span>
                                                                                    <div style={{ fontSize: 10, marginTop: 5 }}>{contact.Owner_Contact}</div>
                                                                                </div>
                                                                            )}
                                                                            {contact.Assistant_Name && (
                                                                                <div>
                                                                                    <span style={{ fontSize: 12, fontWeight: 'bold' }}>Assistant Name:</span>
                                                                                    <div style={{ fontSize: 10, marginTop: 5 }}>{contact.Assistant_Name}</div>
                                                                                </div>
                                                                            )}
                                                                            {contact.Assistant_Contact && (
                                                                                <div>
                                                                                    <span style={{ fontSize: 12, fontWeight: 'bold' }}>Assistant Contact:</span>
                                                                                    <div style={{ fontSize: 10, marginTop: 5 }}>{contact.Assistant_Contact}</div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                    <div style={{ width: '100%', marginTop: 10 }}>
                                                        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                            {imageData?.length > 0 ? (
                                                                imageData.map(({ key, value }, imgIndex) => (
                                                                    <img
                                                                        key={imgIndex}
                                                                        src={value}
                                                                        alt={key}
                                                                        onError={(e) => e.target.src = 'https://via.placeholder.com/150'}
                                                                        style={{
                                                                            width: '250px',
                                                                            height: 'auto',
                                                                            borderRadius: 5
                                                                        }}
                                                                    />
                                                                ))
                                                            ) : (
                                                                null
                                                            )}
                                                        </div>
                                                    </div>
                                                    {!!data?.Invoices &&
                                                        <div style={{ width: '100%', marginTop: 20 }}>
                                                            <h3>INVOICES</h3>
                                                            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                                                                {data?.Invoices?.map((invoice, index) => (
                                                                    <div key={index} style={{ width: '250px', }}>
                                                                        <Card style={{ border: '1px solid #ccc', borderRadius: 5, padding: 10 }}>
                                                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                                <h5>{invoice.Store}</h5>
                                                                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                                                                                    {(invoice?.Replanned === "Yes" || cancelledInvoices.includes(invoice.Invoice_Id)) &&
                                                                                        <img
                                                                                            src={require('../../../assets/images/deliveryRequestCancel.png').default}
                                                                                            style={{
                                                                                                width: '25px',
                                                                                                height: 'auto',
                                                                                                borderRadius: 5,
                                                                                                marginRight: 10
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                    {(invoice?.Cancelled === "Yes" || replannedInvoices.includes(invoice.Invoice_Id)) &&
                                                                                        <img
                                                                                            src={require('../../../assets/images/orderRequest.png').default}
                                                                                            style={{
                                                                                                width: '25px',
                                                                                                height: 'auto',
                                                                                                borderRadius: 5
                                                                                            }}
                                                                                        />
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <p><span style={{ fontWeight: 'bold' }}>Total Raw Cases:</span> {invoice.Total_Raw_Cases}</p>
                                                                            <p><span style={{ fontWeight: 'bold' }}>Store Contact:</span> {invoice.Store_Contact}</p>
                                                                            <p><span style={{ fontWeight: 'bold' }}>Ticket No:</span> {invoice.Ticket_No}</p>
                                                                            <div>
                                                                                {(activeTab === "1" && !replannedInvoices.includes(invoice.Invoice_Id)) && (
                                                                                    <Button
                                                                                        color="warning"
                                                                                        size="sm"
                                                                                        disabled={isButtonDisabled || cancelledInvoices.includes(invoice.Invoice_Id)}
                                                                                        onClick={() => {
                                                                                            onSelectRequests('replanned', invoice?.Invoice_Id);
                                                                                        }}
                                                                                    >
                                                                                        Replan
                                                                                    </Button>
                                                                                )}
                                                                                {(activeTab === "1" && !cancelledInvoices.includes(invoice.Invoice_Id)) && (
                                                                                    <Button
                                                                                        style={{ marginLeft: 10 }}
                                                                                        color="danger"
                                                                                        size="sm"
                                                                                        disabled={replannedInvoices.includes(invoice.Invoice_Id)}
                                                                                        onClick={() => {
                                                                                            onSelectRequests('cancelled', invoice?.Invoice_Id);
                                                                                        }}
                                                                                    >
                                                                                        Cancel
                                                                                    </Button>
                                                                                )}
                                                                            </div>
                                                                        </Card>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    }

                                                </>
                                            );
                                        })()
                                    )}
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <div>
                                <MapView
                                    coordinates={coordinates}
                                />
                            </div>
                        </Card>
                    </Col>
                </Row>
            </div>
            <div className="disabled-backdrop-modal">
                <Fragment>
                    <Modal
                        isOpen={remarksModalOpen}
                        toggle={() => setModalOpen(!remarksModalOpen)}
                        className="modal-dialog-centered"
                        backdrop={false}
                    >
                        <ModalHeader
                            toggle={() => {
                                setRemarksModalOpen(!remarksModalOpen);
                            }}
                        >
                            Give Remarks
                        </ModalHeader>
                        <ModalBody>
                            <Input
                                placeholder="write here..."
                                onChange={(e) => {
                                    setUserRemark(e?.target?.value);
                                }}
                                style={{ marginTop: 20 }}
                            />
                        </ModalBody>
                        <ModalFooter>
                            {!proceedClick &&
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        onUpdateRquestAPI()
                                    }}
                                >
                                    Proceed
                                </Button>
                            }
                        </ModalFooter>
                    </Modal>
                </Fragment>
            </div>
        </>
    );
};

export default WorkFlowDetail;
